import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const foehn: FC = () => {
  const titleImage = "_DSC2131.jpg"
  const image1 = "_DSC2088.jpg"
  const image2 = "_DSC2092.jpg"
  const image3 = "_DSC2100.jpg"
  const image4 = "_DSC2123.jpg"
  const image5 = "_DSC2124.jpg"
  const image6 = "_DSC2132.jpg"

  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Blatter Foehn/" + titleImage}
            alt="Ghidoni"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Blatter-Föhn 2020
          <h5 className="single-project-text">
            Nel nucleo di Fesnèd a Mergoscia due famiglie sono proprietarie di
            una casa di vacanza che era stata ristrutturata negli anni settanta.
            Si cotraddistingueva da dei timpani in legno, una tettoia piana e
            una copertura in carta catramata a vista. Questa casa era
            addirittura illustrata in un libro del canton Ticino nel quale si
            evidenziava come non bisognerebbe fare delle ristrutturazioni.
            L’obiettivo è stato quindi quello di correggere la situazione
            tramite interventi mirati a migliorare l’aspetto della costruzione e
            di integrarla con le altre case del nucleo. A livello razionale fare
            un tetto in piode risultava troppo impegnativo e si è proceduto con
            una copertura in tegole grigie curando il dettaglio di gronda
            eseguito in larice non trattato.
          </h5>
        </h3>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Blatter Foehn/" + image1}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Blatter Foehn/" + image2}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Blatter Foehn/" + image3}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Blatter Foehn/" + image4}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Blatter Foehn/" + image5}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Blatter Foehn/" + image6}
            alt="Alambicco"
          />
        </div>
      </div>
    </Layout>
  )
}

export default foehn
